<template lang="pug">
    .main-wrapper.procedimentos-credenciado-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(v-if='verResumo' icon='jam jam-chevrons-left' @click='voltar()')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub / Med Empresa / <b>Gestão de Pedidos</b>

        Dialog.dialogAutorizar(header='Adicionar' :modal='true' :visible.sync='dialogAdicionar' @hide='fecharAdicionar')
            .p-grid(style='overflow: hidden;')
                .p-col-12.ta-center Deseja Adicionar um procedimento ou consulta ao pedido?
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingAdicionar' strokeWidth='5' style="width:50px;height:50px")
                    Button.p-button-warning(v-else icon='jam jam-plus-circle' label='Confirmar'
                        @click='adicionarProcedimento()')
        
        Dialog.dialogAutorizar(header='Autorizar Pedido' :modal='true' :visible.sync='dialogAutorizar' @hide='fecharAutorizar')
            .p-grid(style='overflow: hidden;')
                .p-col-12.ta-center Deseja Autorizar o pedido?
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingAutorizar' strokeWidth='5' style="width:50px;height:50px")
                    Button.p-button-warning(v-else icon='jam jam-plus-circle' label='Confirmar'
                        @click='criarAtendimento()')

        Dialog.dialogAutorizar(header='Cancelar Pedido' :modal='true' :visible.sync='dialogCancelar' @hide='fecharCancelar')
            .p-grid(style='overflow: hidden;')
                .p-col-12.ta-center Deseja Cancelar o pedido?
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingCancelar' strokeWidth='5' style="width:50px;height:50px")
                    Button.p-button-danger(v-else icon='jam jam-minus-circle' label='Cancelar'
                        @click='cancelar()')

        Dialog.dialogCancelarPedidoGerado(header='Cancelar Pedido Gerado' :modal='true' :visible.sync='dialogCancelarPedidoGerado' @hide='fecharCancelar')
            .p-grid(style='overflow: hidden;')
                .p-col-12.ta-center
                    h4 
                        strong Deseja realmente cancelar esse pedido? s/n

                .p-col-12.ta-center
                    label.form-label Motivo:
                    Textarea(type='text' v-model='ds_motivo' :autoResize="true" rows="5" cols="30" @input="checkMotivo()")

                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingCancelar' strokeWidth='5' style="width:50px;height:50px")
                    Button.p-button-danger(v-else label='Sim' :disabled="disabledButtonCancelar"
                        @click='cancelarPedidoGerado(cdAtendimento)')
                    Button.p-button-danger.ml-2(label='Não'
                        @click='dialogCancelarPedidoGerado = false')

        TabView(@tab-change='onTabChange' v-if ='!verResumo')
            TabPanel(header='Pedidos Pendentes' :active="getTabAtiva('Pedidos Pendentes')")
                div(v-if='!verResumo')
                    Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-6
                                label.form-label Nome:
                                .p-inputgroup
                                    InputText(@keyup.enter.native='filters.nm_pessoa_fisica.length<3 ? "" : applyFilters()' v-model='filters.nm_pessoa_fisica'
                                        @keyup='checkEmptyField("nm_pessoa_fisica")' )
                                    Button(icon='jam jam-search' @click='applyFilters()')

                            .p-col-12.p-md-3
                                label.form-label CPF:
                                .p-inputgroup
                                    InputMask(@keydown.enter='applyFilters()' v-model='filters.nr_cpf'
                                        @keyup='checkEmptyField("nr_cpf")' mask='999.999.999-99')
                                    Button(icon='jam jam-search' @click='applyFilters()')

                            .p-col-12.p-md-3
                                Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

                    CustomDataTable(:waiting='waiting' :header="'Lista de Pedidos'" :tValue='list' :tData.sync='tableData')
                
            
            TabPanel(header='Pedidos Gerados' :active="getTabAtiva('Pedidos Gerados')")

                Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
                    .p-grid.p-fluid.p-align-end

                        .p-col-12.p-md-3
                            label.form-label Número:
                            .p-inputgroup
                                InputText(@keyup.enter.native='applyGeradosFilters()' v-model='filtersGerados.id')
                                Button(icon='jam jam-search' @click='applyGeradosFilters()')

                        .p-col-12.p-md-3
                            label.form-label CPF:
                            .p-inputgroup
                                InputText(@keyup.enter.native='applyGeradosFilters()' v-model='filtersGerados.nr_cpf')
                                Button(icon='jam jam-search' @click='applyGeradosFilters()')

                        .p-col-12.p-md-3
                            label.form-label Cliente:
                            .p-inputgroup
                                InputText(@keyup.enter.native='applyGeradosFilters()' v-model='filtersGerados.nm_pessoa_fisica')
                                Button(icon='jam jam-search' @click='applyGeradosFilters()')
                        
                        .p-col-12.p-md-3
                            label.form-label Data da agenda:
                            .p-inputgroup
                                Calendar(
                                    v-model='filtersGerados.dt_agenda'
                                    dateFormat="dd/mm/yy" :locale="ptbr"
                                    :manualInput='false'
                                    :touchUI='windowInnerWidth < 576'
                                    :showButtonBar="true"
                                    @clear-click='applyGeradosFilters()')
                                Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyGeradosFilters()')
                                Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" type='button' @click='filtersGerados.dt_agenda=null; applyGeradosFilters()')
                        .p-col-12.p-md-3
                            label.form-label Código da guia:
                            .p-inputgroup
                                InputText( 
                                    @keyup.enter.native='applyGeradosFilters()' 
                                    v-model='filtersGerados.cd_transacao_financeira'
                                )
                                Button(icon='jam jam-search' @click='applyGeradosFilters()')
                        .p-col-12.p-md-3
                            label.form-label Estabelecimento:
                            .p-inputgroup
                                MultiSelect(
                                    v-model='filtersGerados.cd_estabelecimento_at'
                                    :options='options.estabelecimentos'
                                    optionLabel='text'
                                    optionValue='value'
                                    placeholder='Todos'
                                    optionKey="value"
                                    @change='applyGeradosFilters()'
                                )
                        .p-col-12.p-md-3
                            label.form-label Procedimento:
                            .p-inputgroup
                                InputText(
                                    @keyup.enter.native='filtersGerados.nm_procedimento.length<3 ? "" : applyGeradosFilters()' 
                                    v-model='filtersGerados.nm_procedimento'
                                    placeholder='Digite um procedimento'
                                )
                                Button(icon='jam jam-search' @click='applyGeradosFilters()')
                        .p-col-12.p-md-3
                            label.form-label Status:
                            Dropdown( v-model='filtersGerados.ie_status' :options='options.ie_status' :filter='options.ie_status.length > 0'
                                dataKey='value' optionLabel='label' optionValue='value' @change='applyGeradosFilters()' )
                        
                        .p-col-12.p-md-2
                            Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

                Panel.datatable(header='Lista de pedidos gerados')
                    ProgressBar(v-if='waitingGerados' mode="indeterminate")
                    .ta-center(v-else-if='!atendimentos.length')
                        p Não existem atendimentos.
                    div(v-else)
                        Paginator.mb-1(
                            :rows='paginatorGerado.per_page'
                            :first='(paginatorGerado.page - 1) * paginatorGerado.per_page'
                            :totalRecords='paginatorGerado.count'
                            @page="onPageGerados($event)")

                        DataTable(:value='atendimentos' :selection.sync="selected"
                            selectionMode="single" dataKey="id" )
                            Column(headerStyle='width:12%' bodyStyle='text-align:center' header='N° atendimento')
                                template(#body='props')
                                    span {{ $root.padStart(props.data.id, 8) }}
                            Column( headerStyle='width:20%'  header='Cliente' field='nm_pessoa_fisica')
                            Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Usuario' field='nm_usuario_cri')
                            Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Data' field='dt_realizado')
                            Column(headerStyle='width:5%' bodyStyle='text-align:center' header='Qtd' field='nr_quant_itens')
                            Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Valor total' field='nr_valor_total_f')
                            Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Status' field='ie_status')
                                template(#body='props')
                                    div(v-if='props.data.ie_status == "A"') 
                                        <span> Aberto</span>
                                    div(v-else-if='props.data.ie_status == "F"') 
                                        <span> Finalizado</span>
                                    div(v-else-if='props.data.ie_status == "C"') 
                                        <span> Cancelado</span>
                                    div(v-else-if='props.data.ie_status == "R"') 
                                        <span> Realizado</span>
                            Column(headerStyle='width:10%' bodyStyle='text-align:center' header='Motivo' field='ds_motivo')
                            Column(headerStyle='width:5%' bodyStyle='text-align:center')
                                template(#body='props')
                                    Button.p-button-rounded(icon='jam jam-chevron-right' @click='onAtendimentoSelect(props.data.id)')
                                    Button.p-button-rounded.p-button-danger(icon='jam jam-trash' v-if="props.data.ie_status == 'A'" @click='dialogCancelarPedidoGerado = true;cdAtendimento = props.data.id')
                
                        Paginator.mb-1(
                            :rows='paginatorGerado.per_page'
                            :first='(paginatorGerado.page - 1) * paginatorGerado.per_page'
                            :totalRecords='paginatorGerado.count'
                            @page="onPageGerados($event)")
        
        .ta-right.mt-4.mb-4(v-if='verResumo && !autorizado')
            Button(label='Adicionar' icon="jam jam-plus" @click="abrirAdicionar()")
        ProgressBar(v-if='waitingGerado' mode='indeterminate')
        div(v-else-if='verResumo')
            Panel.datatable(header='Lista de guias')
                ProgressBar(v-if='waitingResumo' mode='indeterminate')
                .ta-center(v-else-if='!selected.length') Nenhum horário encontrado
                span(v-else)
                    DataTable(:value="selected")
                        Column(headerStyle='width: 15%;' bodyStyle='font-size: 90%; font-weight:700;' field='nm_paciente' header='Paciente' :sortable="true")
                            template(#body='props')
                                .cell.cellclick(v-tooltip.top="'Copiar nome do paciente'" @click='copy(props.data.nm_paciente, "Nome do paciente")')
                                    span.my-4 {{ props.data.nm_paciente }}
                        Column(headerStyle='width: 10%;' field='nr_cpf_f' header='CPF' bodyStyle='text-align:center; font-size: 90%; font-weight:700;' :sortable="true")
                            template(#body='props')
                                .cell.cellclick(v-tooltip.top="'Copiar CPF'" @click='copy(props.data.nr_cpf, "CPF do paciente")')
                                    span.my-4 {{ props.data.nr_cpf_f ? props.data.nr_cpf_f : ' - ' }}
                        
                        Column(headerStyle='width: 25%;' bodyStyle='font-size: 90%; font-weight:700;' header='Procedimento(s)')
                            template(#body='props')
                                div(v-if='props.data.ds_procedimento') 
                                    <span class="label-qtd">{{props.data.nr_quantidade}}x</span> {{props.data.ds_procedimento}}
                                    Button.copy-button-codigo(icon='jam jam-files' type='button' v-tooltip.top="'Copiar código cbhbm'" @click="copy(props.data.cd_procedimentos[0].cd_cbhbm, 'Código cbhpm do procedimento')")
                                div(v-else)
                                    p(v-for='i in props.data.cd_procedimentos' :key='i.id')
                                        span &bull; <span class="label-qtd">{{i.nr_quantidade}}x</span> {{ i.nm_procedimento }}
                                        Button.copy-button-codigo(icon='jam jam-files' type='button' v-tooltip.top="'Copiar código cbhbm'" @click="copy(i.cd_cbhbm, 'Código cbhpm do procedimento')")
                        Column(headerStyle='width: 13%;' field='nm_estabelecimento' header='Estabelecimento' bodyStyle='text-align:center; font-size: 90%; font-weight:700;' :sortable="true")
                            template(#body='props')
                                .cell.cellclick(v-tooltip.top="'Copiar Nome do estabelecimento'" @click="copy(props.data.nm_estabelecimento, 'Nome do estabelecimento')")
                                    span {{ props.data.nm_estabelecimento? props.data.nm_estabelecimento : ' - ' }}
                                    
                        Column(headerStyle='width: 15%;' field='nm_especialista' header='Especialista' bodyStyle='text-align:center; font-size: 90%; font-weight:700;' :sortable="true")
                            template(#body='props')
                                .cell.cellclick(v-tooltip.top="'Copiar Nome do Especialista'" @click="copy(props.data.nm_especialista, 'Nome do especialista')")
                                    span {{ props.data.nm_especialista? props.data.nm_especialista : ' - ' }}

                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center; font-size: 90%; font-weight:700;' field='nr_valor_total_f' header='Valor')
                        Column(v-if='!autorizado' headerStyle='width: 7%; text-align: center' header="Ações")
                            template(#body='props')
                                .ta-center
                                    Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                        v-tooltip.top="'Cancelar'"
                                        icon="jam jam-minus-circle"
                                        @click="abrirCancelar([props.data.id])"
                                    )

                    .box-total
                        p <b>Total: <span class='span-total'>{{ formatPrice(totalDebito()) }}</span></b>

        BuscarPaciente.buscar-paciente(ref='buscarPaciente2' :ocultaLimpar='true' :disabled='autorizado' :class="{verResumo: !verResumo || aba != 'Pedidos Pendentes'}" label='Cliente' :cancelarPopup='true' :medEmpresa='true' :hideCadastro='true' @empresaInativa='dialogEmpresaSuspensa = !!$event, motivoSuspensaoEmpresa = $event')
        
        .ta-right.mt-4(v-if='verResumo')
            div(v-if='autorizado')      
                label.form-label(style='margin-right: 155px') Nº Atend.:
                InputText.numeroPedido(readonly :value='`${ $root.padStart(autorizadoData.id, 8) }`')
                Button.copy-button(icon='jam jam-files' type='button' v-tooltip.top="'Copiar Nº de atendimento'" @click="copy($root.padStart(autorizadoData.id, 8), 'Nº de Atendimento')")
            div(v-else)
                Button.p-button-danger(label='Cancelar Solicitação' icon='jam jam-close' @click='abrirCancelar()' style='font-size:13px;min-width:180px;font-weight:550;margin-right: 10px;')
                Button(label='Gerar Pedido' icon='jam jam-check' type='button' :loading='waitingAutorizar' @click="abrirAutorizar()" style='font-size:13px;min-width:180px;font-weight:550;')

</template>

<style lang="scss">
    .procedimentos-credenciado-listar {
        .copy-button {
			color: black;
			background-color: #ddd;
			border: 1px solid #a6a6a6;
			&:hover {
				background-color: #a6a6a6 !important;
				border: 1px solid #ddd !important;
			}
		}
        .cellclick {
            cursor: pointer;
        }

        .copy-button-codigo {
            max-width: 28px;
            max-height: 28px;
            margin-left: 5px;
			color: black;
			background-color: #ddd;
			border: 1px solid #a6a6a6;
			&:hover {
				background-color: #a6a6a6 !important;
				border: 1px solid #ddd !important;
			}
		}
        .numeroPedido {
			color: #000;
			font-weight: 600;
			background-color: #ddd;
		}
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
            .text-ellipsis {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
        .dialogEditarObservacoes {
            width: 96%;
            max-width: 600px;
            textarea {
                height: 200px;
            }
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 24px;
            height: 24px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
            }
            .jam {
                color: #fff;
            }
        }
        .waitingObservacoes {
            width: 29px;
            height: auto;
        }
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
        .box-total {
			border: 1px solid #c8c8c8;
			border-top: none;
			text-align: right;
			padding: 0.857em;
			background-color: #f4f4f4;
			.span-total {
				width: 13.5%;
				margin-left: 1%;
				text-align: center;
			}
			p {
				margin: 0;
			}
		}
        .label-qtd{
			display:inline-block;
			padding:5px;
			background:#EEE;
			border-radius:5px;
			border:1px solid #CCC;
		}
        .buscar-paciente{
            margin-top:30px;
            width:100%;
            &.verResumo {
                display: none;
            }
        }
        .dialogAutorizar{
            width: 350px;
            overflow: hidden;
        }
    }
</style>

<script>
    import Button from 'primevue/button'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Textarea from 'primevue/textarea'
    import Tooltip from 'primevue/tooltip'
    import BuscarPaciente from './../Paciente/BuscarPaciente'
    import TabView from 'primevue/tabview'
	import TabPanel from 'primevue/tabpanel'
    import CustomDataTable from '../CustomComponents/CustomDataTable.vue'
    import { pCalendarLocale_ptbr, copyToClipboard } from './../../utils'
    import Calendar from 'primevue/calendar'
    import moment from "moment"
    import { Estabelecimento } from './../../middleware'
    import MultiSelect from 'primevue/multiselect';

    const _ = require('lodash')

    import { Caixas, MedEmpresa } from './../../middleware'

    export default {
        created () { 
            this.applyFilters();
            this.getEstabelecimentos()
            if(this.$route.query.cd_titular)
                this.onGuiaAppSiteSelect({ cd_titular_id: this.$route.query.cd_titular })

         },
        components: { Button, Column, DataTable, DataView, Dialog, Dropdown, InputText, CustomDataTable, TabView, TabPanel,
            Paginator, Panel, ProgressBar, ProgressSpinner, Textarea, Tooltip, InputMask, Calendar, BuscarPaciente, MultiSelect },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                verResumo: false,
                aba: "Pedidos Pendentes",
                selectedId: null,
                waitingResumo: false,
                dialogEmpresaSuspensa: false,
                windowInnerWidth: window.innerWidth,
                dialogAutorizar: false,
                dialogCancelar: false,
                dialogCancelarPedidoGerado: false,
                dialogAdicionar: false,
                waitingAutorizar: false,
                waitingCancelar: false,
                waitingAdicionar: false,
                waitingGerado: false,
                cancelarData: null,
                autorizadoData: null,
                autorizado: false,
                waiting: false,
                waitingObservacoes: false,
                expandedRows: [],
                ptbr: pCalendarLocale_ptbr,
                atendimentos: [],
                waitingGerados: false,
                ds_motivo: '',
                cdAtendimento: null,
                disabledButtonCancelar: true,
                paginatorGerado: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: 10,
					count: 0
				},
                filtersGerados: {
                    cd_transacao_financeira: null,
					nm_pessoa_fisica: '',
					nr_cpf: '',
					id: '',
                    dt_agenda: null,
                    cd_estabelecimento_at: [],
                    nm_procedimento: '',
                    ie_status: 'A'
				},
                filters: {
					nm_pessoa_fisica: '',
					nr_cpf: '',
                    dt_agendamento: null,
                    dt_agenda: null,
				},
                options: {
                    estabelecimentos: [],
                    ie_status: [
                        { value: null, label: '- TODOS -' },
                        { value: 'A', label: 'Abertos' },
                        { value: 'F', label: 'Finalizados' },
                        { value: 'C', label: 'Cancelados' },
                        { value: 'R', label: 'Realizados' }
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: 10,
                    count: 0
                },
                order: {
                    field: 'cd_cbhpm',
                    sortOrder: 1
                },
                tableData: [
					{header: 'Código do Titular', field: 'cd_titular_id', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
					{header: 'Pacientes', field: 'pacientes', hStyle: {width:'30%'},
                        bStyle: {
                            textAlign: 'left'
                        },
						body: [
							{
								bType: 'custom',
								custom: this.pacientes
							}
						]
                    },
					{header: 'Qtd. Horários', field: 'qtd_horarios', hStyle: {width:'10%'},
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
					// {header: 'Valor Total', field: 'nr_valor_total', hStyle: {width:'10%'},
                    //     bStyle: {
                    //         textAlign: 'center'
                    //     },
					// 	body:[
					// 		{
					// 			bType: 'custom',
					// 			custom: this.valor_total
					// 		}
					// 	]
                    // },
					{header: '', field: '', hStyle: {width:'5%'},
                        body: [
                            {
                                bType: 'button',
                                icon: 'jam jam-chevron-right',
                                classes: 'p-button-rounded',
                                tooltip: 'Ver Resumo',
                                action: this.onGuiaAppSiteSelect,
                            }
                        ],
                        bStyle: {
                            textAlign: 'center',
                            padding: 0
                        }
                    },
				]
            }
        },
        methods: {
            checkMotivo(){
                if (this.ds_motivo.length > 5){
                    this.disabledButtonCancelar = false
                }else{
                    this.disabledButtonCancelar = true
                }
            },
            formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            onPageGerados (ev) {
				this.applyGeradosFilters(ev.page + 1)
			},
            onSort (ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            expandRow(data) {
                if (this.expandedRows.includes(data.id)) {
                    let index = this.expandedRows.indexOf(data.id)
                    if (index !== -1) this.expandedRows.splice(index, 1)
                } else {
                    this.expandedRows.push(data.id)
                }
            },
            getEstabelecimentos(){
				Estabelecimento.findAllClean().then(response => {
					if (response.status == 200) {
						response.data.forEach(e => {
							this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
						})
						this.estabelecimentoLoaded = true
					}
				})
			},
            checkOverflow(elementId) {
                let element = document.getElementById(elementId)
                if (!element) return

                let currentOverflow = element.style.overflow

                if ( !currentOverflow || currentOverflow === "visible" )
                    element.style.overflow = "hidden"

                let isOverflowing = element.clientHeight < element.scrollHeight
                if (isOverflowing) {
                    for (let i = 0; i < element.children.length; i++) {
                        element.children[i].hidden = !element.children[i].hidden
                    }
                }

                element.style.overflow = currentOverflow
            },
            getList (params) {
                this.waiting = true
                this.expandedRows = []
                return Caixas.getAgendamentosNovo(params).then(response => {
                    if (([200,201]).includes(response.status)) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                        this.$nextTick().then(() => {
                            this.list.forEach(item => {
                                this.checkOverflow(`INFO_${item.id}`)
                                this.checkOverflow(`PREPARO_${item.id}`)
                            })
                        })
                    }
                    this.waiting = false
                })
            },
            applyFilters () {
                let params = { ie_associado_med_empresa:true, paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] || typeof this.filters[key] === 'boolean') params[key] = this.filters[key]
                })
                this.getList(params)
            },
            nome_titular(item) {
				return `
						<b> ${ item.nm_pessoa_fisica } </b>
						<br />
						<em> CPF: ${ item.nr_cpf } </em>
						<br />
					`
			},
            pacientes(item){
				let pacientes = ''
				_.each(item.pacientes, i => {
					pacientes = pacientes +
									`<li>
										${this.nome_titular({
												nm_pessoa_fisica: i.nm_paciente,
												nr_cpf: i.nr_cpf_paciente
											})}
									</li>
									`
							})
				return `
						<ul>
							${pacientes}
						</ul>

					`
			},
            valor_total(item){
				return item.valor_total? this.formatPrice(item.nr_valor_total) : " -- "
			},
            onGuiaAppSiteSelect(data){
				this.waitingResumo = true
				this.cd_titular_id = data.cd_titular_id
				this.verResumo = true
				Caixas.getAgendamentos({ cd_titular: this.cd_titular_id, ie_associado_med_empresa: true }).then(res => {
                    this.waitingResumo = false
					if (([200,201]).includes(res.status)) {
						this.paginator.count = res.data.count
						res.data.forEach(ag => {
							ag.nr_valor_total_f =
								new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
									.format(ag.nr_valor_total)
							if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
							if (ag.cd_paciente.cd_pai != undefined) ag.cd_paciente.cd_pai.nr_cpf_f = ag.cd_paciente.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
						})
						this.selected = res.data
                        this.buscarPagante(data.nr_cpf)
					}
				})
			},
            totalDebito () {
                let sum = this.selected ? 
                    this.selected.reduce((acc, item) => { return acc + +item.nr_valor_total }, 0) : 0
                sum = sum.toFixed(2)
                return parseFloat(sum)
            },
            buscarPagante(val) {
                if(val){
                    this.$refs.buscarPaciente2.filtro.selected.value = val
                    this.$refs.buscarPaciente2.buscar()
                    
                }
            },
            criarAtendimento (isAdicionar = false) {
                let dataSend = {
                        cd_pessoa_fisica: this.$refs.buscarPaciente2.cd_pessoa_fisica,
                        cd_horarios_list: this.selected.map(item => (item.id)),
                        cd_origem_atendimento: 1645,
                        cd_estabelecimento: this.$refs.buscarPaciente2.titular.empresa_associada_f.id,
                        cd_empresa: this.$refs.buscarPaciente2.titular.empresa_associada_f.id
                }
                this.waitingAutorizar = true;
                this.waitingAdicionar = true;
                MedEmpresa.criarAtendimento(dataSend).then(response => {
                    this.dialogAutorizar = false;
                    this.waitingAdicionar = true;
                    if(([200,201]).includes(response.status)){
                        this.$toast.success('Pedido gerado com sucesso')
                        this.autorizadoData = response.data;
                        this.autorizado = true;
                        if(isAdicionar){
                            sessionStorage.setItem('tipo_atendimento', "E")
                            this.$router.push(`/med-empresa/atendimento/${ response.data.id }/`)
                        }
                    }else{this.$toast.error('Falha ao gerar pedido')}
                })
            },
            copy(data, label) {
                if(label == 'Nº de Atendimento') copyToClipboard(`[#PEDIDO:${data}]`)
                else copyToClipboard(data)
				this.$toast.success(`${label} copiado` )
			},
            abrirAutorizar(){ 
                if(this.$refs?.buscarPaciente2?.cd_pessoa_fisica) this.dialogAutorizar = true
                else this.$toast.error('Um cliente precisa ser selecionado')
            },
            abrirAdicionar(){ 
                this.dialogAdicionar = true;
            },
            fecharAutorizar(){
                this.waitingAutorizar = false;
            },
            fecharAdicionar(){
                this.waitingAdicionar = false;
            },
            abrirCancelar(itens = []){
                this.dialogCancelar = true;
                this.cancelarData = itens.length != 0? itens : this.selected.map(item => (item.id))
            },
            fecharCancelar(){
                this.dialogCancelar = false;
                this.cancelarData = null;
            },
            cancelar(){
                let cancelarPedido = this.cancelarData.length == this.selected.length;
                this.waitingCancelar = true;
                let dataSend = { horarios: this.cancelarData }
                MedEmpresa.cancelarProcedimentos(dataSend).then(response => {
                    this.waitingCancelar = false;
                    if(([200,201]).includes(response.status)){
                        this.$toast.success('Solicitação excluida com sucesso');
                        if(cancelarPedido) this.$router.go(0);
                        this.selected = this.selected.filter(item => !this.cancelarData.includes(item.id));
                    } else {
                        this.$toast.error('Erro na exclusão de solicitação');
                    }
                    this.dialogCancelar = false;
                })
            },
            onTabChange (event) {
				if (event.tab.header == 'Pedidos Pendentes') {
					this.selected = [];
					this.applyFilters(0,true)
				} else {
					this.selected = []
					this.applyGeradosFilters(0)
				}
                this.aba = event.tab.header
			},
            applyGeradosFilters (page) {
				this.paginatorGerado.page = page || 1
				let params = {
					paginacao: true,
					page: this.paginatorGerado.page,
					per_page: this.paginatorGerado.per_page,
                    ie_apenas_app_site: true
				}

				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filtersGerados).forEach((key) => {
					if (this.filtersGerados[key]) {
                        if (key == 'dt_agenda'){
                            params[key] = this.filtersGerados.dt_agenda.toISOString().split('T')[0]
                        }else if (key == 'cd_estabelecimento_at') {
                            params[key] = [this.filtersGerados[key]] 
                        }else{
                            
                            params[key] = this.filtersGerados[key] }
                        }
                    }
                )

				return this.getGerados(params)
			},
            getGerados (params) {
				this.waitingGerados = true
				return MedEmpresa.getAtendimentos({ ...params, ie_simplificado: true }).then(response => {
					this.waitingGerados = false
					this.atendimentos = []
					if (([200,201]).includes(response.status)) {
						this.paginatorGerado.count = response.data.count
						this.atendimentos = response.data.results
						this.atendimentos.forEach(atendimento => {
							// let nr_valor_total = 0
							// atendimento.horarioagendamento_set.forEach(ag => {
							// 	ag.nr_valor_total_f =
							// 		new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
							// 			.format(ag.nr_valor_total)
							// 	ag.nr_valor_total_antigo_f =
							// 		new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
							// 			.format(ag.nr_valor_total_antigo)
							// 	if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
							// 	if (ag.cd_paciente.cd_pai) ag.cd_paciente.cd_pai.nr_cpf_f = ag.cd_paciente.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
							// 	nr_valor_total += parseFloat(ag.nr_valor_total)
							// })
							if (! atendimento.cd_pessoa_fisica) atendimento.cd_pessoa_fisica = { nm_pessoa_fisica: null }
							atendimento.dt_realizado = moment(atendimento.dt_atualizado).format('DD/MM/YYYY')
							atendimento.hr_realizado = moment(atendimento.dt_atualizado).format('HH:mm')
							atendimento.nr_valor_total_f = this.formatPrice(atendimento.nr_valor_total)
						})
					}
					return new Promise(resolve => { resolve(this.atendimentos) })
				})
			},
            onAtendimentoSelect (cd_titular) {
				this.cd_titular_id = cd_titular
                if(this.selected.cd_pessoa_fisica?.nr_cpf) this.buscarPagante(this.selected.cd_pessoa_fisica.nr_cpf)
                this.waitingGerado = true;
                MedEmpresa.getAtendimento(cd_titular).then(response => {
                    this.waitingGerado = false;
                    if(([200,201]).includes(response.status)){
                        this.autorizadoData = response.data;
                        this.selected = response.data.horarioagendamento_set.map(item => ({
                            ...item,
                            nr_cpf_f: item.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
                            nr_valor_total_f: this.$root.formatPrice(item.nr_valor)
                        }))
                        this.autorizado = true;
                        this.verResumo = true;
                    }else{
                        this.$toast.error(response?.data?.detail)
                    }
                })
			},
            cancelarPedidoGerado(cdAtendimento){
                let dataSend = { 
                    ie_status: "C", 
                    ds_motivo: this.ds_motivo
                }
                MedEmpresa.cancelarPedidoGerado(cdAtendimento, dataSend).then(response => {
                    // this.waitingCancelar = false;
                    if(([200,201]).includes(response.status)){
                        this.$toast.success('Pedido cancelado com sucesso');
                        this.dialogCancelarPedidoGerado = false;
                        this.applyGeradosFilters()
                    } else {
                        if ('non_field_errors' in response.data) {
                            this.$toast.error(response.data['non_field_errors'][0])
                        } else {
                            this.$toast.error('Erro na exclusão de solicitação');
                        }
                    }
                    // this.dialogCancelar = false;
                })
            },
            adicionarProcedimento(){
                this.criarAtendimento(true)
            },
            clearFilters(){
                this.filtersGerados = {
					nm_pessoa_fisica: '',
					nr_cpf: '',
					id: '',
				}
                this.filters = {
					nm_pessoa_fisica: '',
					nr_cpf: '',
                    dt_agendamento: null,
                    dt_agenda: null,
				}
                this.applyFilters();
                this.applyGeradosFilters();
            },
            voltar(){
                if(!this.verResumo){
                    this.$router.go(-1)
                }
                if(this.verResumo && !this.$route.query.cd_titular ){
                    this.verResumo = false
                }
                else{
                    this.$router.push({ path: `/gestao-pedidos/listar?pg=${ this.$route.query.pg || 1 }` })
                }
            },
            getTabAtiva(header){
                return this.aba === header
            }
        }
    }
</script>
